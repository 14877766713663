import React from "react";
// import About from "./components/About";
import Contact from "./components/Contact";
// import Navbar from "./components/Navbar";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
// import Footer from "./components/Footer";
import Experience from "./components/Experience";
import PhotoCanvas from "./components/photoCanvas";
import PhotoCanvas2 from "./components/photoCanvas2";
import { useRef} from 'react';
import { FaGithub, FaLinkedin, FaArrowUp} from "react-icons/fa";
import { useState, useEffect} from "react";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { CodeIcon } from "@heroicons/react/solid";
import { PiCoffeeFill } from "react-icons/pi";

const wordArray = ['an AI enthusiast', 'a student', 'a software engineer', 'a computer scientist', 'a programmer', 'a mathematician',  'a data scientist'];

const WordChanger = () => {
	const [currWord, setCurrWord] = useState(wordArray[0]);
	const [isActive] = useState(true);

	const index = useRef(0);
	useEffect(() => {
		let interval = null;
		if (isActive) {
			interval = setInterval(() => {
				index.current++;
        index.current = index.current % wordArray.length;
				setCurrWord(wordArray[index.current]);
			}, 2000);
		}
		return () => clearInterval(interval);
	});

	return (
		<div>
			<p> <span className="text-indigo-300">I'm {currWord}</span>.</p>
		</div>
	);
};

export default function App() {
  const skillsRef = useRef(null);
  const projRef = useRef(null);
  const expRef = useRef(null);
  const contactRef = useRef(null);
  const top = useRef(null);
  return (
    // NAVBAR
    <main className="text-sky-100 body-font scroll-smooth">
      <title>Andrew Robertson</title>
      <div ref={top}></div>
      <header className="bg-gradient-to-tl from-gray-800 to-gray-700 md:sticky top-0 z-10 sticky scroll-smooth">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center sticky top-0">
          <a className="title-font font-medium text-sky-100 mb-4 md:mb-0">
          <span href="/#aboutMe" className="ml-3 text-xl flex items-center">
            <div className="flex items-center transform hover:rotate-180 transition-transform">
              <PiCoffeeFill className="mr-2 width-7" /> 
            </div>
            <button onClick={() => {top.current?.scrollIntoView({behavior: 'smooth'}) }} className="hover:text-white flex items-center">
              Andrew Robertson
            </button>
          </span>
          </a>
          <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-sky-100	flex flex-wrap items-center text-base justify-center">
            <button onClick={() => {projRef.current?.scrollIntoView({behavior: 'smooth'}) }} className="mr-5 hover:text-white"> 
              Projects
            </button>
            <button onClick={() => {skillsRef.current?.scrollIntoView({behavior: 'smooth'}) }} className="mr-5 hover:text-white"> 
              Skills
            </button>
            <button onClick={() => {expRef.current?.scrollIntoView({behavior: 'smooth'}) }} className="mr-5 hover:text-white"> 
              Experience
            </button>
          </nav>
          <a
            href="https://github.com/NeoDrew" target="_blank" rel="noreferrer"
            className="inline-flex text-gray-700 items-center bg-sky-100 py-1 px-1 focus:outline-none border-transparent border hover:border-gray-400 hover:bg-white hover:text-orange-500 rounded mt-4 md:mt-0 transition-all duration-700 ease-in-out">
            <FaGithub className="w-7 h-7 items-center bg-inherit" />
          </a>
          <div className="text-transparent">
            ...
          </div>
          <a
            href="https://www.linkedin.com/in/andrewrobertsonamr/" target="_blank" rel="noreferrer"
            className="inline-flex text-gray-700 items-center bg-sky-100 py-1 px-1 focus:outline-none border-transparent border hover:border-gray-400 hover:bg-white hover:text-blue-500 rounded mt-4 md:mt-0 transition-all duration-700 ease-in-out">
            <FaLinkedin className="w-7 h-7 items-center bg-inherit" />
          </a>
        </div>
      </header>

      {/* ABOUT SECTION */}
      <section id="about">
        <div className="bg-gradient-to-b from-gray-600 via-gray-700 to-gray-900">
          <div className="container mx-auto flex px-10 md:flex-row flex-col items-center bg-inherit">
            <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
              <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-sky-100">
                Hi, I'm Andrew.
                <br className="hidden lg:inline-block" />
                {WordChanger()}
              </h1>
              <p className="mb-8 leading-relaxed text-sky-100">
                ...and a hard working student with professional experience in Cyber Security, Networking & FinTech. Current student at the University of Manchester,
                studying Computer Science. Lover of everything technology with a strong passion for Mathematics, Software Engineering & AI/ML.
              </p>
              <div className="flex justify-center">
                <button
                  onClick={() => {contactRef.current?.scrollIntoView({behavior: 'smooth'}) }}
                  className="inline-flex text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg transition-all duration-300">
                  
                  Contact Me
                </button>
                <button
                  onClick={() => {projRef.current?.scrollIntoView({behavior: 'smooth'}) }}
                  className="ml-4 inline-flex text-sky-100 bg-gradient-to-tr from-sky-500 to-indigo-500 border-0 py-2 px-6 opacity-100 hover:opacity-90 focus:outline-none hover:bg-indigo-300 hover:text-white rounded text-lg transition-all duration-300">
                  <CodeIcon className="w-8 pr-1 text-sky-100" /> See My Past Projects
                </button>
              </div>
            </div>
            <div className="h-auto max-w-xs relative max-w-sm">
              <img
                className="object-cover object-center rounded relative"
                alt="Me"
                src="./portrait.png"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="bg-gradient-to-tl from-gray-800 to-gray-700 py-0.5 width-full"></div>

      <div ref={projRef}>
        <Projects />
      </div>
      
      <div className="bg-gradient-to-tl from-gray-800 to-gray-700 py-0.5 width-full"></div>
      
      <div>
        <PhotoCanvas />
      </div>

      <div className="bg-gradient-to-tl from-gray-800 to-gray-700 py-0.5 width-full"></div>

      <div ref={skillsRef}>
        <Skills />
      </div>

      <div className="bg-gradient-to-tl from-gray-800 to-gray-700 py-0.5 width-full"></div>

      <div>
        <PhotoCanvas2 />
      </div>

      <div className="bg-gradient-to-tl from-gray-800 to-gray-700 py-0.5 width-full"></div>

      <div ref={expRef}>
        <Experience />
      </div>

      <div className="bg-gradient-to-tl from-gray-800 to-gray-700 py-0.5 width-full"></div>

      <div ref={contactRef}>
        <Contact />
      </div>

      {/* FOOTER */}
      <section id="Footer" className="relative bg-gradient-to-tl from-gray-800 to-gray-700">
        <div className="container py-5 mx-auto px-0 text-sky-100 bg-inherit w-full">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <span className="mr-2">
                <FaPhone />
              </span>
              <span>+44 7554713775</span>
            </div>
            <div className="flex items-center">
              <span className="mr-2">
                <MdEmail />
              </span>
              <a href="mailto:AndrewMaxwellRobertson@gmail.com">AndrewMaxwellRobertson@gmail.com</a>
            </div>
            <div className="flex items-center">
              <span className="mr-2">© ANDREW 2024 - ALL RIGHTS RESERVED</span>
            </div>
            <div className="flex items-center">
              <div className="pr-4 text-xl">Top</div>
              
              <button
                onClick={() => top.current?.scrollIntoView({ behavior: 'smooth' })}
                className="text-gray-800 text-xl bg-sky-100 border-0 py-2 px-2 focus:outline-none hover:bg-indigo-500 hover:text-white rounded text-lg transition-all duration-200 ease-in-out"
              >
                <FaArrowUp />
              </button>
            </div>
          </div>
        </div>
      </section>

    </main>
  );
}